import React from "react";
import {graphql} from "gatsby";

import Layout from "/src/components/layouts/Layout";
import SectionLayoutGrid from "/src/components/layouts/SectionLayoutGrid";

import {LottieAnimation} from "../../components/styled/lotti/LottieAnimations";
import Col from "../../components/styled/grid/Col";
import {Body, Title} from "../../components/styled/typography/Typography";
import {ButtonContainer, ButtonLink} from "../../components/styled/button/ButtonComponents";

import circuit from "/static/assets/animations/circuit/circuit01.json";

const ThankYou = ({data}) => {

    const {page} = data

    return (
        <Layout lang={page.locale}
                seo={{title: page.locale === "hu" ? "Üzenet elküldve" : "Message sent", isIndexable: false}}>
            <LottieAnimation animationData={circuit} left="true"/>
            <SectionLayoutGrid>
                <Col grid center>
                    <Title color="white" textAlign="center" marginBottom="normal">
                        {page.thank_you.title}
                    </Title>
                    <Body color="body-text" textAlign="center" marginBottom="small">
                        {page.thank_you.subtitle}
                    </Body>
                    <Body color="body-text" textAlign="center" bold>
                        {page.thank_you.subtitle_bold}
                    </Body>
                    <Col grid center>
                        <ButtonContainer>
                            <ButtonLink to={page.thank_you.button.link} color="primary-brand">
                                {page.thank_you.button.name}
                            </ButtonLink>
                        </ButtonContainer>
                    </Col>
                </Col>
            </SectionLayoutGrid>
        </Layout>
    )
}

export const query = graphql`
   query GetSingleApiThankYou($locale: String) {
       page:strapiThankYouPage(locale: {eq: $locale}) {
        locale
        thank_you {
          title
          subtitle_bold
          subtitle
          button {
            name
            link
          }
        }
      }
   }
 `

export default ThankYou